import { NgIf, NgFor } from '@angular/common';
import { Component, OnInit, AfterViewInit, ViewChild, HostListener, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormControl,
    AbstractControl,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';

import {
    IUIPopoverConfig,
    UIPopoverTargetDirective,
    UIPopoverDirective,
    UIModule,
    UiTimeZoneService,
    UiTimeZone
} from '@bannerflow/ui';

import { fadeInAnimation } from 'src/app/shared/animations';
import { PasswordRequirementsComponent } from 'src/app/shared/components';
import {
    PasswordRequirements,
    PasswordRequirementsChecklist,
    PasswordType,
    UserTitle
} from 'src/app/shared/models';
import { BFHttpError, ProfileService } from 'src/app/shared/services';
import { MatchValidator } from 'src/app/shared/validators';

import { NewUser, UserRegistrationData } from '../../models';
import { RegistrationService } from '../../services/registration.service';

@Component({
    templateUrl: 'registration.component.html',
    styleUrls: ['registration.component.scss'],
    animations: [fadeInAnimation],
    host: { '[@fadeInAnimation]': '' },
    imports: [NgIf, UIModule, FormsModule, ReactiveFormsModule, NgFor, PasswordRequirementsComponent]
})
export class RegistrationComponent implements OnInit, AfterViewInit {
    private formBuilder: FormBuilder = inject(FormBuilder);
    private profileService: ProfileService = inject(ProfileService);
    private registrationService: RegistrationService = inject(RegistrationService);
    private timeZoneService: UiTimeZoneService = inject(UiTimeZoneService);
    private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    private router: Router = inject(Router);

    @ViewChild('passwordRequirementsPopover')
    public passwordRequirementsPopover: UIPopoverDirective;

    public uiPopoverConfig: IUIPopoverConfig = {
        arrowPosition: 'left',
        panelClass: 'fit-content-class',
        position: 'right',
        width: 'auto',
        minWidth: 'auto',
        hasBackdrop: false,
        offset: { x: -10, y: -35 } // Should be 18 since border is 1px and input height is 3.4rem=34px
    };

    public passwordRequirementsChecklist: PasswordRequirementsChecklist = {
        hasLowerAndUppercase: false,
        hasNumber: false,
        hasSpecialCharacter: false,
        minimumCharacters: false,
        passwordsMatching: false
    };

    public errorMessage: string;
    public isLoading: boolean;
    public newUserForm: FormGroup;
    public isPasswordUser: boolean;
    public passwordRequirements: PasswordRequirements;
    public shownPasswordRequirements: PasswordRequirementsChecklist;
    public submitTouched: boolean;
    public timeZones: UiTimeZone[];
    public userTitles: UserTitle[];
    public isUserTitlesLoaded: boolean;
    public user = new NewUser();
    public displayPassword = false;

    private isPopoverOpen: boolean;
    private selectedPasswordType: string;

    get firstName(): AbstractControl {
        return this.newUserForm.get('givenName');
    }

    get lastName(): AbstractControl {
        return this.newUserForm.get('familyName');
    }

    get email(): AbstractControl {
        return this.newUserForm.get('email');
    }

    get password(): AbstractControl {
        return this.newUserForm.get('password');
    }

    get confirmPassword(): AbstractControl {
        return this.newUserForm.get('passwordConfirmation');
    }

    @HostListener('document:click', ['$event'])
    public documentClick(event: MouseEvent): void {
        const element = event.target as HTMLElement;
        const parent = element && (element.parentNode as HTMLElement);

        const isPasswordElement = element.id === 'password' || parent.id === 'password';
        const isConfirmPasswordElement =
            element.id === 'passwordConfirmation' || parent.id === 'passwordConfirmation';

        if (!isConfirmPasswordElement && !isPasswordElement) {
            this.passwordRequirementsPopover.close();
        }
    }

    public ngOnInit(): void {
        if (
            !this.activatedRoute.snapshot.queryParams.userId ||
            !this.activatedRoute.snapshot.queryParams.invitationId
        ) {
            this.router.navigate(['/oops']);
        }

        this.isLoading = true;

        this.registrationService
            .getUserRegistrationForm()
            .then((res: UserRegistrationData) => {
                this.user.email = res.email;
                this.passwordRequirements = res.passwordRequirements;
                this.isPasswordUser = res.isPasswordUser;
                this.isLoading = false;
                this.buildUserForm();
            })
            .catch(() => this.router.navigate(['/invitation-expired']));
    }

    public ngAfterViewInit(): void {
        this.setupTitles();
        this.setupTimeZones();
    }

    public buildUserForm(): void {
        this.newUserForm = this.formBuilder.group(
            {
                givenName: ['', [Validators.required, Validators.minLength(2)]],
                familyName: ['', [Validators.required, Validators.minLength(2)]],
                email: [this.user.email, [Validators.required, Validators.minLength(2)]],

                password: [
                    '',
                    this.isPasswordUser
                        ? [
                              Validators.required,
                              Validators.minLength(this.passwordRequirements.minimumCharacters),
                              Validators.pattern(new RegExp(this.passwordRequirements.regex))
                          ]
                        : []
                ],

                passwordConfirmation: [
                    '',
                    this.isPasswordUser
                        ? [
                              Validators.required,
                              Validators.minLength(this.passwordRequirements.minimumCharacters)
                          ]
                        : []
                ]
            },
            { validator: MatchValidator('password', 'passwordConfirmation') }
        );

        this.newUserForm.controls.password.statusChanges.subscribe(() => {
            const password: FormControl = this.newUserForm.controls.password as FormControl;
            const confirmPassword: FormControl = this.newUserForm.controls
                .passwordConfirmation as FormControl;
            if (password.valid) {
                confirmPassword.enable();
            } else {
                confirmPassword.disable();
            }
        });
    }

    public onSubmit(): void {
        this.submitTouched = true;

        if (this.newUserForm.invalid || !this.user.acceptsToC) {
            return;
        }

        this.user.userId = this.activatedRoute.snapshot.queryParams.userId;
        this.user.invitationId = this.activatedRoute.snapshot.queryParams.invitationId;
        this.user = new NewUser().deserialize({
            ...this.newUserForm.value,
            ...this.user
        });

        this.isLoading = true;

        this.registrationService
            .finishRegistration(this.user)
            .then(() => {
                this.isLoading = false;
                this.registrationService.redirectToBannerflowClient();
            })
            .catch((err: BFHttpError) => {
                this.isLoading = false;
                if (err.code >= 500 && err.code < 600) {
                    this.router.navigate(['/oops']);
                }
                this.errorMessage = err.message;
            });
    }

    public changeShownRequirements(type: PasswordType): void {
        this.passwordCheck();
        if (type === PasswordType.Password) {
            const pass: PasswordRequirementsChecklist = { ...this.passwordRequirementsChecklist };
            delete pass.passwordsMatching;
            this.shownPasswordRequirements = pass;
        } else if (type === PasswordType.ConfirmPassword) {
            const pass: PasswordRequirementsChecklist = {
                passwordsMatching: this.passwordRequirementsChecklist.passwordsMatching
            };
            this.shownPasswordRequirements = pass;
        }
    }

    public showPassword(): void {
        this.displayPassword = !this.displayPassword;
    }

    public onPasswordEvents(
        passwordType: string,
        target: UIPopoverTargetDirective,
        popOver: UIPopoverDirective
    ): void {
        if (passwordType === 'password') {
            this.changeShownRequirements(PasswordType.Password);
        } else {
            this.changeShownRequirements(PasswordType.ConfirmPassword);
        }

        if (this.selectedPasswordType !== passwordType) {
            if (this.isPopoverOpen) {
                this.isPopoverOpen = false;
                popOver.close();
            }
            this.selectedPasswordType = passwordType;
        }

        if (!this.isPopoverOpen) {
            this.isPopoverOpen = true;
            popOver.open(target);
            popOver.onClose.subscribe(() => {
                this.isPopoverOpen = false;
            });
        }
    }

    public passwordCheck(): void {
        const controls = this.newUserForm.controls;
        this.passwordRequirementsChecklist = {
            minimumCharacters:
                controls.password.value.length >= this.passwordRequirements.minimumCharacters,
            hasNumber: !!(
                controls.password.value.match(/\d/) && controls.password.value.match(/\d/)[0]
            ),
            hasSpecialCharacter: controls.password.value.match(/[^a-zA-Z0-9]/),
            hasLowerAndUppercase:
                controls.password.value.match(/[a-z]/) && controls.password.value.match(/[A-Z]/),
            passwordsMatching:
                controls.passwordConfirmation.value &&
                !(this.newUserForm.errors && this.newUserForm.errors.result.mismatch)
        };
    }

    private setupTimeZones(): void {
        const clientTimezone: number = new Date().getTimezoneOffset() * -1;
        this.timeZones = this.timeZoneService.getTimeZones();

        const userTimeZone: UiTimeZone | undefined = this.timeZones.find((timezone: UiTimeZone) => {
            return timezone.offset === clientTimezone;
        });
        this.user.timeZone = !userTimeZone ? 'GMT Standard Time' : userTimeZone.id;
    }

    private setupTitles(): void {
        this.profileService.getUserTitles().then((userTitles: UserTitle[]) => {
            this.userTitles = userTitles;
            this.isUserTitlesLoaded = true;
        });
    }
}
