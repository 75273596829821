import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIModule, UiTimeZoneService } from '@bannerflow/ui';

import { ApiService, ProfileService } from './services';

@NgModule({
    imports: [CommonModule, FormsModule, UIModule],
    exports: [UIModule],
    providers: [ApiService, ProfileService, UiTimeZoneService]
})
export class SharedModule {}
